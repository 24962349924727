/* styles.css */
.cube-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-scroll {
  animation: scroll 30s linear infinite;
  width: fit-content;
  display: flex;
}

.cube {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-style: preserve-3d;
  transform: translate(-50%, -50%);
  animation: rotateCube 10s infinite linear;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.cube::before, .cube::after {
  content: '';
  position: absolute;
  width: 100px;
  height: 100px;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.cube:nth-child(1) {
  transform: translateZ(50px);
}
.cube:nth-child(2) {
  transform: rotateY(90deg) translateZ(50px);
}
.cube:nth-child(3) {
  transform: rotateY(180deg) translateZ(50px);
}
.cube:nth-child(4) {
  transform: rotateY(-90deg) translateZ(50px);
}
.cube:nth-child(5) {
  transform: rotateX(90deg) translateZ(50px);
}
.cube:nth-child(6) {
  transform: rotateX(-90deg) translateZ(50px);
}

@keyframes rotateCube {
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(360deg) rotateY(360deg);
  }
}

.particle {
  width: 10px;
  height: 10px;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  animation: floatParticle 10s infinite;
}

.particle:nth-child(1) {
  animation-delay: 0s;
}
.particle:nth-child(2) {
  animation-delay: 2s;
}
.particle:nth-child(3) {
  animation-delay: 4s;
}
.particle:nth-child(4) {
  animation-delay: 6s;
}
.particle:nth-child(5) {
  animation-delay: 8s;
}

@keyframes floatParticle {
  0% {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-100px) translateX(100px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0) translateX(200px);
    opacity: 0;
  }
}

/* Testimonial styles */
.testimonial-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
/* styles.css */

.swiper-container {
  padding: 10px; /* Optional: add some padding around the Swiper */
}

.swiper-slide {
  transition: transform 0.3s ease; /* Smooth scaling effect */
}

.swiper-slide:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

@media (max-width: 640px) {
  .swiper-slide {
    padding: 10px; /* Adjust padding for small screens */
  }
}

@media (min-width: 640px) {
  .swiper-slide {
    padding: 20px; /* More padding for larger screens */
  }
}

